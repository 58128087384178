"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapMetadata = exports.PACKAGE_METADATA = void 0;

var _lodash = require("lodash");

var _consts = require("../consts");

var _device = require("../device");

var _PACKAGE_METADATA_VS_;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PACKAGE_METADATA_VS_TO_MEDIADB = (_PACKAGE_METADATA_VS_ = {
  collectionId: 'packageId',
  __metadata_last_modified: 'lastModified',
  __representativeThumbnailsNoAuth: 'thumbnails',
  representativeItems: 'representativeItem',
  __user: 'user',
  created: 'created'
}, _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_COLLECTION_TYPE, 'type'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_STATUS, 'packageStatus'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_DURATION, 'packageDuration'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_LOCATION, 'packageLocation'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_NAME, 'packageName'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_DESCRIPTION, 'packageDescription'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_PREVIEW, 'packagePreview'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_LABEL_ITEM_ID, 'labelItemId'), _defineProperty(_PACKAGE_METADATA_VS_, _device.SONOVA_DEVICE_TEMPLATE_NAME, 'deviceName'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_INTERLOCUTOR_NAME, 'interlocutorName'), _defineProperty(_PACKAGE_METADATA_VS_, _consts.SONOVA_PACKAGE_GROUP_NAME, 'packageGroupName'), _PACKAGE_METADATA_VS_);
var PACKAGE_METADATA = {
  VS_TO_MEDIADB: PACKAGE_METADATA_VS_TO_MEDIADB,
  MEDIADB_TO_VS: (0, _lodash.invert)(PACKAGE_METADATA_VS_TO_MEDIADB)
};
exports.PACKAGE_METADATA = PACKAGE_METADATA;

var mapMetadata = function mapMetadata(metadataObj, metadataMap) {
  return (0, _lodash.mapKeys)((0, _lodash.pickBy)(metadataObj, function (value, key) {
    return metadataMap[key] !== undefined;
  }), function (value, key) {
    return metadataMap[key];
  });
};

exports.mapMetadata = mapMetadata;