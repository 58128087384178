import { item } from '@vidispine/vdt-api';
import { VIDEO_PROXY_TAG, AUDIO_PROXY_TAG } from 'mediadb-lib';
import { useQuery } from 'react-query';
import { get as _get } from 'lodash';

const parseProxy = (itemType) => {
  const mimeType = _get(itemType, 'shape.0.mimeType.0');
  const uri = _get(itemType, 'shape.0.containerComponent.file.0.uri.0');
  if (!uri || !mimeType) {
    throw new Error('No valid device proxy found');
  }
  return {
    uri,
    mimeType,
  };
};

export default function useDeviceProxy(device) {
  return useQuery(
    ['deviceProxy', device.id],
    () =>
      item
        .getItem({
          itemId: device.id,
          queryParams: {
            content: 'shape',
            tag: [AUDIO_PROXY_TAG, VIDEO_PROXY_TAG].join(','),
            methodMetadata: [
              {
                key: 'format',
                value: 'SIGNED-AUTO',
              },
            ],
          },
        })
        .then(({ data }) => parseProxy(data)),
    {
      enabled: !!device.id,
      staleTime: 300000,
    },
  );
}
