"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INTERLOCUTORS_DEF = exports.TECHNICAL_LABEL_TRACK_DEF = void 0;

var _vdtJs = require("@vidispine/vdt-js");

var _lodash = require("lodash");

var _consts = require("./consts");

var _device = require("./device");

var _metadata = require("./metadata");

var _error = _interopRequireDefault(require("./error"));

var _utils = require("./utils");

var _consts2 = require("./label/consts");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var TECHNICAL_LABEL_TRACK_DEF = {
  group: [_consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACKS, _consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK],
  list: true,
  uuid: 'uuid',
  properties: {
    name: {
      field: _consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_NAME
    },
    status: {
      field: _consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_STATUS
    },
    channelUuid: {
      field: _consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_CHANNEL_UUID
    },
    itemId: {
      field: _consts2.SONOVA_PACKAGE_TECHNICAL_LABEL_TRACK_ITEM_ID
    }
  }
};
exports.TECHNICAL_LABEL_TRACK_DEF = TECHNICAL_LABEL_TRACK_DEF;
var INTERLOCUTORS_DEF = {
  group: [_consts.SONOVA_INTERLOCUTORS, _consts.SONOVA_INTERLOCUTOR],
  list: true,
  uuid: 'uuid',
  properties: {
    name: {
      field: _consts.SONOVA_INTERLOCUTOR_NAME
    },
    customMetadata: {
      group: _metadata.SONOVA_PERSON_CUSTOM_GROUP,
      parseProperties: _utils.parseGroupFields
    },
    devices: {
      group: [_consts.SONOVA_DEVICES, _consts.SONOVA_DEVICE],
      list: true,
      uuid: 'uuid',
      properties: {
        id: {
          field: 'itemId'
        },
        deviceTemplate: {
          group: _device.SONOVA_DEVICE_TEMPLATE,
          reference: 'uuid',
          properties: _device.DEVICE_TEMPLATE_PROPERTIES
        },
        position: {
          field: _consts.SONOVA_DEVICE_POSITION
        },
        customMetadata: {
          group: _metadata.SONOVA_DEVICE_CUSTOM_GROUP,
          parseProperties: _utils.parseGroupFields
        },
        channels: {
          group: [_consts.SONOVA_CHANNELS, _consts.SONOVA_CHANNEL],
          list: true,
          uuid: 'uuid',
          properties: {
            name: {
              field: _consts.SONOVA_CHANNEL_NAME
            },
            filename: {
              field: _consts.SONOVA_CHANNEL_FILENAME
            },
            playbackPosition: {
              field: _consts.SONOVA_CHANNEL_PLAYBACK
            }
          }
        },
        sensors: {
          group: [_consts.SONOVA_SENSORS, _consts.SONOVA_SENSOR],
          list: true,
          uuid: 'uuid',
          properties: {
            itemId: {
              field: 'itemId'
            },
            position: {
              field: _consts.SONOVA_SENSOR_POSITION
            },
            channels: {
              group: [_consts.SONOVA_CHANNELS, _consts.SONOVA_CHANNEL],
              list: true,
              uuid: 'uuid',
              properties: {
                name: {
                  field: _consts.SONOVA_CHANNEL_NAME
                },
                filename: {
                  field: _consts.SONOVA_CHANNEL_FILENAME
                }
              }
            },
            customMetadata: {
              group: _metadata.SONOVA_SENSOR_CUSTOM_GROUP,
              parseProperties: _utils.parseGroupFields
            },
            sensorTemplate: {
              group: _consts.SONOVA_SENSOR_TEMPLATE,
              reference: 'uuid',
              properties: {
                name: {
                  type: String,
                  field: _consts.SONOVA_SENSOR_TEMPLATE_NAME
                },
                type: {
                  type: String,
                  field: _consts.SONOVA_SENSOR_TEMPLATE_TYPE
                }
              }
            }
          }
        }
      }
    }
  }
};
exports.INTERLOCUTORS_DEF = INTERLOCUTORS_DEF;

var parsePackageGroupMetadata = function parsePackageGroupMetadata(metadata) {
  var infTimespan = (0, _lodash.get)(metadata, 'timespan.0');
  var packageGroupMetadata = (0, _utils.parseTimespanByDef)({
    packageGroupId: {
      field: 'collectionId'
    },
    owner: {
      field: '__user'
    },
    created: {
      field: 'created'
    },
    type: {
      field: _consts.SONOVA_COLLECTION_TYPE
    }
  }, infTimespan);

  if (packageGroupMetadata.type !== _consts.SONOVA_COLLECTION_TYPES.PACKAGE_GROUP) {
    throw new _error["default"]('Package group not found', {
      status: 404
    });
  }

  var packageGroupGroupMetadata = (0, _utils.parseTimespanByDef)({
    group: _consts.SONOVA_PACKAGE_GROUP,
    properties: {
      name: {
        field: _consts.SONOVA_PACKAGE_GROUP_NAME
      },
      description: {
        field: _consts.SONOVA_PACKAGE_GROUP_DESCRIPTION
      },
      isActive: {
        type: _utils.bool,
        field: _consts.SONOVA_PACKAGE_GROUP_IS_ACTIVE
      }
    }
  }, infTimespan);
  return _objectSpread(_objectSpread({}, packageGroupMetadata), packageGroupGroupMetadata);
};

var parsePackageMetadata = function parsePackageMetadata(metadata) {
  var infTimespan = (0, _lodash.get)(metadata, 'timespan.0');
  var packageMetadata = (0, _utils.parseTimespanByDef)({
    packageId: {
      field: 'collectionId'
    },
    lastModified: {
      field: '__metadata_last_modified'
    },
    packageGroupId: {
      field: '__parent_collection'
    },
    thumbnails: {
      field: '__representativeThumbnailsNoAuth'
    },
    representativeItem: {
      field: 'representativeItems'
    },
    owner: {
      field: '__user'
    },
    created: {
      field: 'created'
    },
    labelItemId: {
      field: _consts.SONOVA_LABEL_ITEM_ID
    },
    type: {
      field: _consts.SONOVA_COLLECTION_TYPE
    },
    preview: {
      field: _consts.SONOVA_PACKAGE_PREVIEW
    },
    lastModifiedBy: {
      field: _consts.SONOVA_MODIFIED_BY
    }
  }, infTimespan);

  if (packageMetadata.type !== _consts.SONOVA_COLLECTION_TYPES.PACKAGE) {
    throw new _error["default"]('Package not found', {
      status: 404
    });
  }

  var packageGroupMetadata = (0, _utils.parseTimespanByDef)({
    group: _consts.SONOVA_PACKAGE,
    properties: {
      status: {
        field: _consts.SONOVA_PACKAGE_STATUS
      },
      duration: {
        field: _consts.SONOVA_PACKAGE_DURATION
      },
      location: {
        field: _consts.SONOVA_PACKAGE_LOCATION
      },
      name: {
        field: _consts.SONOVA_PACKAGE_NAME
      },
      labeler: {
        field: _consts.SONOVA_PACKAGE_LABELER
      },
      description: {
        field: _consts.SONOVA_PACKAGE_DESCRIPTION
      },
      audioSamplingRate: {
        field: _consts.SONOVA_PACKAGE_AUDIO_SAMPLING_RATE
      },
      customMetadata: {
        group: _metadata.SONOVA_PACKAGE_CUSTOM_GROUP,
        parseProperties: _utils.parseGroupFields
      }
    }
  }, infTimespan);
  var packageGroupGroupMetadata = (0, _utils.parseTimespanByDef)({
    group: _consts.SONOVA_PACKAGE_GROUP,
    properties: {
      packageGroupName: {
        field: _consts.SONOVA_PACKAGE_GROUP_NAME
      },
      packageGroupDescription: {
        field: _consts.SONOVA_PACKAGE_GROUP_DESCRIPTION
      },
      packageGroupIsActive: {
        type: _utils.bool,
        field: _consts.SONOVA_PACKAGE_GROUP_IS_ACTIVE
      }
    }
  }, infTimespan);
  var interlocutorsMetadata = (0, _utils.parseTimespanByDef)({
    interlocutors: INTERLOCUTORS_DEF
  }, infTimespan);
  var packageTechnicalLabelTracks = (0, _utils.parseTimespanByDef)({
    technicalLabelTracks: TECHNICAL_LABEL_TRACK_DEF
  }, infTimespan);
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, packageMetadata), packageGroupMetadata), interlocutorsMetadata), packageTechnicalLabelTracks), packageGroupGroupMetadata);
};

var limitTimeCodeBy = function limitTimeCodeBy(timeCode, duration) {
  return [timeCode.samples < duration.samples ? timeCode.samples : (0, _lodash.floor)(duration.samples), timeCode.timeBase.toText(true)].join('@');
};

var parseExtracts = function parseExtracts(timespans, packageDuration) {
  var timedTimespans = timespans.filter(function (ts) {
    return !(ts.start === '-INF' || ts.end === '+INF');
  });
  var truncatedTimespans = timedTimespans.map(function (_ref) {
    var start = _ref.start,
        end = _ref.end;
    var formattedStart = (0, _vdtJs.formatTimeCodeText)(start);
    var formattedEnd = (0, _vdtJs.formatTimeCodeText)(end);
    return {
      start: limitTimeCodeBy(formattedStart, (0, _vdtJs.formatSeconds)((0, _lodash.toNumber)(packageDuration), formattedStart.timeBase)),
      end: limitTimeCodeBy(formattedEnd, (0, _vdtJs.formatSeconds)((0, _lodash.toNumber)(packageDuration), formattedEnd.timeBase))
    };
  });
  var sortedTimespans = (0, _lodash.sortBy)(truncatedTimespans, [function (_ref2) {
    var start = _ref2.start;
    return (0, _vdtJs.formatTimeCodeText)(start).samples;
  }]); // Merge adjacent extracts since vidicore does not?

  var mergedTimespans = [];

  for (var i = 0; i < sortedTimespans.length; i += 1) {
    var curr = sortedTimespans[i];
    var prev = mergedTimespans.slice(-1).pop();
    var prevEnd = prev && (0, _vdtJs.formatTimeCodeText)(prev.end);

    if (prev && prevEnd.samples >= (0, _vdtJs.formatTimeCodeText)(curr.start).conformTimeBase(prevEnd.timeBase).samples) {
      prev.end = curr.end;
    } else {
      var start = curr.start === '-INF' ? '0' : curr.start;
      var end = curr.end === '+INF' ? (0, _vdtJs.formatSeconds)(packageDuration).toText() : curr.end;
      var startSeconds = (0, _vdtJs.formatTimeCodeText)(start).toSeconds();
      var endSeconds = (0, _vdtJs.formatTimeCodeText)(end).toSeconds();
      var duration = endSeconds - startSeconds;
      var coverage = duration / parseFloat(packageDuration, 10);
      mergedTimespans.push({
        start: start,
        end: end,
        duration: duration,
        coverage: coverage
      });
    }
  }

  return mergedTimespans;
};

var _default = {
  parsePackageMetadata: parsePackageMetadata,
  parseExtracts: parseExtracts,
  parsePackageGroupMetadata: parsePackageGroupMetadata
};
exports["default"] = _default;