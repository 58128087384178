import { useQuery, useInfiniteQuery } from 'react-query';
import { pkg } from 'mediadb-lib';
import React from 'react';
import MediaDBError from 'mediadb-lib/src/error';

const useFindPackagesWithMarkerExtracts = (query, { number, iteration, enabled }) => {
  const {
    data: { pages: packagesPages = [] } = {},
    fetchNextPage,
    isFetching: isFetchingPackages,
    isError: isErrorPackages,
    error: errorPackages,
  } = useInfiniteQuery(
    ['findPackages', query],
    ({ pageParam = '*' }) =>
      pkg.findPackages(query, {
        cursor: pageParam,
        number,
        // Facet and hits will be the same in subsequent requests
        includeFacet: pageParam === '*',
        includeHits: pageParam === '*',
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
      enabled,
    },
  );

  const {
    data: { pages: packageExtractsPages = [] } = {},
    fetchNextPage: fetchMoreExtracts,
    isFetching: isFetchingExtracts,
    isError: isErrorExtracts,
    error: errorExtracts,
  } = useInfiniteQuery(
    ['findPackagesMarkerExtracts', query],
    ({ pageParam = 0 }) =>
      (() => {
        const { packages: latestPackages = [] } = packagesPages[pageParam] || {};
        return pkg.findPackagesMarkerExtracts(query, latestPackages);
      })(),
    {
      getNextPageParam: () => iteration - 1,
      enabled: enabled && !isFetchingPackages && iteration === packagesPages.length,
    },
  );

  React.useEffect(() => {
    if (
      !isFetchingPackages &&
      packagesPages.length === iteration &&
      packagesPages.length > packageExtractsPages.length
    )
      fetchMoreExtracts();
  }, [
    fetchMoreExtracts,
    isFetchingPackages,
    iteration,
    packagesPages.length,
    packageExtractsPages.length,
  ]);

  let packagesExtracts = {};
  packageExtractsPages.forEach((page) => {
    packagesExtracts = { ...packagesExtracts, ...(page?.packagesExtracts || {}) };
  });

  const packagesWithoutExtracts = packagesPages.flatMap((page) => page.packages);
  const packages = packagesWithoutExtracts.map((p) => ({
    ...p,
    extracts: packagesExtracts[p.packageId] || [],
  }));

  const [{ hits, facet } = {}] = packagesPages;
  return {
    data: {
      hits,
      facet,
      packages,
    },
    isFetchingPackages,
    isFetchingExtracts,
    hasMoreToFetch: packages.length < hits,
    loadMore: () => fetchNextPage(),
    isError: isErrorPackages || isErrorExtracts,
    error: errorPackages || errorExtracts,
  };
};

const useFindPackagesWithMarkerAndTechnicalExtracts = (
  query,
  { number, enabled, isSearchingWithoutOverlap },
) => {
  const {
    data: { hits: maxPossibleHits = 0, facet: possiblyRedundantFacet = [] } = {},
    isFetching: isFetchingInitial,
    isError: isErrorApproximateHitsAndFacet,
    error: errorApproximateHitsAndFacet,
  } = useQuery(['findPackages', query], () => pkg.findPackages(query, { first: 0, number: 0 }), {
    initialData: {},
  });
  const {
    data: { pages: packagesWithExtractsPages = [] } = {},
    fetchNextPage,
    isFetching: isFetchingPackagesAndExtracts,
    isError: isErrorPackagesAndExtracts,
    error: errorPackagesAndExtracts,
  } = useInfiniteQuery(
    ['findPackagesWithMarkerAndTechnicalExtracts', query],
    ({ pageParam = '*' }) =>
      pkg.findPackagesWithMarkerAndTechnicalExtracts(query, {
        cursor: pageParam,
        number,
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
      enabled,
      retry: 1, // Fail fast since requests might take long (even timeout)
    },
  );
  const packages = packagesWithExtractsPages.flatMap((page) => page.packages);
  const amountOfNeglectedPackages = packagesWithExtractsPages
    .map((page) => page.amountOfNeglectedPackages || 0)
    .reduce((partialSum, value) => partialSum + value, 0);

  const amountChecked = packages.length + amountOfNeglectedPackages;
  const packageIds = packages.map((p) => p.packageId);

  // Based on all client-side fetched and filtered packages
  const {
    data: { facet: accurateFacet } = {},
    isFetching: isFetchingFacet,
    isError: isErrorAccurateFacet,
    error: errorAccurateFacet,
  } = useQuery(
    ['findPackagesFacet', query, packageIds],
    () => pkg.findPackagesFacet(query, packageIds),
    {
      initialData: {},
      enabled:
        enabled &&
        !isSearchingWithoutOverlap &&
        maxPossibleHits > 0 &&
        maxPossibleHits === amountChecked,
    },
  );
  return {
    data: {
      ...(isSearchingWithoutOverlap
        ? { hits: maxPossibleHits }
        : { hits: packages.length, maxPossibleHits, amountChecked }),
      facet: (maxPossibleHits === amountChecked && accurateFacet) || possiblyRedundantFacet,
      amountFetched: packages.length,
      packages,
    },
    isFetchingPackagesAndExtracts: isFetchingPackagesAndExtracts || isFetchingInitial,
    isFetchingFacet: isFetchingFacet || isFetchingInitial,
    hasMoreToFetch: packages.length < maxPossibleHits - amountOfNeglectedPackages,
    loadMore: () => fetchNextPage(),
    isError: isErrorApproximateHitsAndFacet || isErrorPackagesAndExtracts || isErrorAccurateFacet,
    error: errorApproximateHitsAndFacet || errorPackagesAndExtracts || errorAccurateFacet,
  };
};

export function useFindPackagesWithExtracts(query, { batchSize, number, iteration, shouldStop }) {
  if (batchSize > number) {
    throw new MediaDBError("'batchSize' can't be larger than 'number'", { status: 400 });
  }

  const isSearchingForTechnical = query.technical?.length > 0;
  const isSearchingForMarker = query.text || query.label?.labels?.length > 0;

  /* Returns packages without extracts, then afterwards fetch/adds extracts to those packages
   * - Accurate hits and facets returned after first package search
   */
  const packagesWithMarkerExtracts = useFindPackagesWithMarkerExtracts(query, {
    number,
    iteration,
    enabled: !isSearchingForTechnical,
  });

  /* Returns packages and extracts simultaneously but in batches according to "batchSize"
   * - Iterates and filters packages until it found number * iteration
   * - All marker and technical timespans are checked if overlapping extracts at the client
   *   - So some packages might be filtered away in client
   *   - Approximate hits and facets until all are fetched
   */
  const packagesWithMarkerAndTechnicalExtracts = useFindPackagesWithMarkerAndTechnicalExtracts(
    query,
    {
      number: batchSize,
      enabled: isSearchingForTechnical,
      // If searching only 1 technical (no overlaps) then maxPossibleHits = hits
      isSearchingWithoutOverlap: query.technical?.length === 1 && !isSearchingForMarker,
    },
  );

  const {
    data: { packages },
    isFetchingPackagesAndExtracts,
    isFetchingPackages,
    isFetchingExtracts,
    isError,
    hasMoreToFetch,
    loadMore,
  } = isSearchingForTechnical ? packagesWithMarkerAndTechnicalExtracts : packagesWithMarkerExtracts;

  const isFetchingPackagesOrExtracts =
    isFetchingPackagesAndExtracts || isFetchingPackages || isFetchingExtracts;

  React.useEffect(() => {
    if (
      hasMoreToFetch &&
      !isFetchingPackagesOrExtracts &&
      !shouldStop &&
      packages.length < number * iteration &&
      !isError
    ) {
      loadMore();
    }
  }, [
    hasMoreToFetch,
    isFetchingPackagesOrExtracts,
    shouldStop,
    packages.length,
    number,
    iteration,
    isError,
    loadMore,
  ]);

  return isSearchingForTechnical
    ? packagesWithMarkerAndTechnicalExtracts
    : packagesWithMarkerExtracts;
}

export function useFindPackages(query, { cursor, first, number }) {
  return useQuery(
    ['findPackages', query, cursor, first, number],
    () => pkg.findPackages(query, { cursor, first, number }),
    {
      initialData: {},
    },
  );
}

export function usePackageLabels({ packageId, start, end }) {
  return useQuery(
    ['labels', packageId, start, end],
    () => pkg.getPackageLabels({ id: packageId, start, end }),
    {},
  );
}
