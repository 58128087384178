import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Collapse, withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = () => ({
  expandedRow: {
    '& > td': {
      border: 'none',
    },
  },
  dragHandle: {
    width: '24px',
    padding: '0',
  },
  dragHandleIcon: {
    display: 'flex',
  },
});

function renderValue(value) {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
}

const DataTable = ({
  classes,
  className,
  columns,
  rows,
  rowKey = 'name',
  getRowKey = () => undefined,
  onClickRow = () => null,
  selectedRows = [],
  selectedRowIndices = [],
  TableProps = {},
  RowComponent = TableRow,
  expandedRows = [],
  ExpandedComponent,
  RowActions,
  RowProps,
  draggable = false,
  getDraggableRowProps = () => null,
}) => {
  const numColumns = columns.length + (RowActions ? 1 : 0);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Table className={className} {...TableProps}>
      <TableHead>
        <TableRow>
          {draggable && <TableCell className={classes.dragHandle} />}
          {columns.map((column) => (
            <TableCell key={column.key}>{column.name}</TableCell>
          ))}
          {RowActions && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows &&
          rows.map((row, i) => {
            const rKey = row[rowKey] || getRowKey(row, i) || row.name;
            return (
              <React.Fragment key={rKey}>
                <RowComponent
                  hover
                  selected={selectedRows.includes(row) || selectedRowIndices.includes(i)}
                  key={rKey}
                  onClick={() => onClickRow(row, i)}
                  className={clsx(expandedRows.includes(rKey) && classes.expandedRow)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(draggable ? getDraggableRowProps(row, i) : {})}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...RowProps}
                >
                  {draggable && (
                    <TableCell className={classes.dragHandle}>
                      <Icon className={classes.dragHandleIcon} color="secondary">
                        <DragIndicatorIcon />
                      </Icon>
                    </TableCell>
                  )}
                  {columns &&
                    columns.map(({ key, render: renderColumn = (v) => v }) => (
                      <TableCell key={key}>{renderValue(renderColumn(row[key]))}</TableCell>
                    ))}
                  {RowActions && (
                    <TableCell key="rowActions">
                      <RowActions index={i} row={row} rowKey={rKey} />
                    </TableCell>
                  )}
                </RowComponent>
                {ExpandedComponent && (
                  <TableRow key={`${rKey}-expandedRow`}>
                    <TableCell
                      style={{
                        padding: 0,
                        borderBottom: expandedRows.includes(rKey)
                          ? '1px solid rgba(224, 224, 224, 1)'
                          : 'none',
                      }}
                      colSpan={numColumns}
                    >
                      <Collapse in={expandedRows.includes(rKey)}>
                        <ExpandedComponent row={row} />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(DataTable);
