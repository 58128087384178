import React from 'react';
import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  header: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
});

const MetadataGrid = ({ classes, fields, header = 'Information' }) => {
  if (!fields || fields?.length < 1) return null;
  return (
    <>
      <Typography className={classes.header}>{header}</Typography>
      <Box className={clsx(classes.root, classes.metadataGrid)}>
        {fields.map(
          (field) =>
            field.value && (
              <React.Fragment key={field.name}>
                <Typography variant="body2" color="textSecondary">
                  {field.label}
                </Typography>
                <span>{field.value}</span>
              </React.Fragment>
            ),
        )}
      </Box>
    </>
  );
};

export default withStyles(styles)(MetadataGrid);
